
                    @import '../common/src/css/resources.scss';
                
.exception {
    max-width: 500px;
    margin: 45px auto;
    padding: 40px;
    background-color: white;
    font-size: 15px;
    font-family: sans-serif;
    line-height: 18px;

    &.alert {
        color: #ff5d62;

        svg {
            path,
            circle {
                fill: #ff5d62;
            }
        }
    }

    .exception__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 18px;
        line-height: 28px;

        svg {
            margin-right: 8px;
        }
    }

    .exception__message {
        display: block;
        margin: 15px 0px;
        color: #555555;
    }

    .exception__code {
        font-size: 18px;
        color: #ff5d62;
    }

    .exception__link {
        display: inline-block;
        cursor: pointer;
        font-weight: 600;
        color: #0072c6;
        text-decoration: none;
    }
}
