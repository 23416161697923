
                    @import '../common/src/css/resources.scss';
                
.action-link {
    color: #068eda;
    cursor: pointer;
    user-select: none;

    &:hover {
        text-decoration: underline;
    }
}
