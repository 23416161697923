
                    @import '../common/src/css/resources.scss';
                
// styles taken from https://alligator.io/css/collapsible/
.exception__details {
    margin-bottom: 1.2rem 0;

    pre {
        font-family: 'Courier New', Courier, monospace;
        color: #333333;
    }

    input[type='checkbox'] {
        display: none;
    }

    .lbl-toggle {
        display: block;

        font-weight: bold;
        font-family: monospace;
        text-transform: uppercase;
        text-align: center;

        padding: 0.5rem;

        color: white;
        background: #0072c6;

        cursor: pointer;

        border-radius: 7px;
        transition: all 0.25s ease-out;
    }

    .lbl-toggle:hover {
        color: #e6e6e6;
    }

    .lbl-toggle::before {
        content: ' ';
        display: inline-block;

        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid currentColor;
        vertical-align: middle;
        margin-right: 0.7rem;
        transform: translateY(-2px);

        transition: transform 0.2s ease-out;
    }

    .toggle:checked + .lbl-toggle::before {
        transform: rotate(90deg) translateX(-3px);
    }

    .exception__details--collapsible-content {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.25s ease-in-out;
    }

    .toggle:checked + .lbl-toggle + .exception__details--collapsible-content {
        max-height: 350px;
    }

    .toggle:checked + .lbl-toggle {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .exception__details--collapsible-content-inner {
        background-color: #e6e6e6;
        border-bottom: 1px solid rgba(250, 224, 66, 0.45);
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        padding: 0.5rem 1rem;
        overflow-y: auto;
    }
}
