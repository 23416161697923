
                    @import '../common/src/css/resources.scss';
                
.loading-bar {
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 5px;
    overflow: hidden;

    .line {
        position: absolute;
        opacity: 0.4;
        background: #0072c6;
        width: 150%;
        height: 5px;
    }

    .subline {
        position: absolute;
        opacity: 0.9;
        background: $cc-lime;
        height: 5px;
        width: 5%;
        left: -5%;
        border-radius: 50%;
    }
    .inc {
        animation: increase 2s infinite;
    }
    .dec {
        animation: decrease 2s 0.5s infinite;
    }
}

@keyframes increase {
    from {
        transform: translate(0, 0) scale(1, 1);
    }
    to {
        transform: translate(150vw, 0) scale(10, 1);
    }
}
@keyframes decrease {
    from {
        transform: translate(0, 0) scale(5, 1);
    }
    to {
        transform: translate(150vw, 0) scale(1, 1);
    }
}
