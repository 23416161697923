
                    @import '../common/src/css/resources.scss';
                
.browser-not-supported {
    @apply flex flex-col justify-center items-center ml-auto mr-auto flex-wrap;

    &__icon {
        @apply text-blue-700 mb-2;
    }

    &__sub-header {
        @apply mt-4 mb-4;
    }

    &__link-list {
        @apply mt-2;
    }

    &__link {
        @apply inline-flex items-center justify-center text-gray-700 bg-white rounded-lg;
        margin-right: 15px;
        padding: 10px 0;
        transition: 0.3s;
        width: 125px;
        &:hover {
            @apply bg-gray-700 text-white;
        }
        &:last-child {
            margin-right: 0;
        }
        &--dark {
            @apply bg-gray-50;
        }
    }

    &__link-icon {
        @apply mr-2;
        font-size: 23px;
    }
}
