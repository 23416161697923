
                    @import '../common/src/css/resources.scss';
                
@import './tailwind.css';
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300i,700|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');

html {
    @apply h-full;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply h-full;
}

#root {
    @apply h-full;

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    h1 {
        @apply text-3xl text-primary;
    }

    h2 {
        @apply text-2xl text-primary;
    }

    h3 {
        @apply text-lg;
    }

    a {
        @apply text-secondary;
    }

    label {
        @apply text-gray-800;
    }

    .browser-ie {
        .overflow-y-auto {
            overflow-y: scroll !important;
        }
    }
}
