// @import "./legacy/colors.scss";

/* Brand colors */

// $cc-dark-blue: #0072c6;
// $cc-md-blue: #1a9eff;
// $cc-light-blue: #4bc5ff;
// $cc-lighter-blue: #d0e8f9;
// $cc-very-light-blue: #E5F7FF;

$cc-lime: #bbff00;

// $cc-dark-gray: #333333;
// $cc-md-gray: #555555;
// $cc-gray-300: #bbbbbb;
// $cc-gray-200: #cccccc;
// $cc-gray: #dddddd;
// $cc-light-gray: #ebecee;
// $cc-extra-light-gray: #f5f5f5;

$primary-blue: #0072c6;
// $secondary: $cc-md-blue;
// $accent: $cc-lime;
// $invalid: #ffbec0;
// $valid: #8cc799;

$cc-error-red: #ff6146;

$confirmation-tick: #6fb97f;
