
                    @import '../common/src/css/resources.scss';
                
body {
    padding: 0px;
    margin: 0px;
}

.site-container {
    @apply flex flex-col w-full;

    height: 100vh;
}
